import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrivacyTable from 'components/Web_User_Interface/720p_Series/Multimedia/Privacy_Mask/privacyTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Privacy Mask",
  "path": "/Web_User_Interface/720p_Series/Multimedia/Privacy_Mask/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Black out areas from your camera's live video stream.",
  "image": "./WebUI_720p_SearchThumb_Multimedia_Privat.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Multimedia_Privat.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Multimedia Menu // Privacy Mask' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Black out areas from your camera`s live video stream.' image='/images/Search/WebUI_720p_SearchThumb_Multimedia_Privat.png' twitter='/images/Search/WebUI_720p_SearchThumb_Multimedia_Privat.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Multimedia/Privat_Maske/' locationFR='/fr/Web_User_Interface/720p_Series/Multimedia/Privacy_Mask/' crumbLabel="Privacy Mask" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <h3 {...{
      "id": "privacy-mask",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#privacy-mask",
        "aria-label": "privacy mask permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Privacy Mask`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`The Privacy Mask Menu allows you to black out areas from your surveillance. E.g. you can still see a part of the public street in front of your house on your surveillance videos. But you cannot adjust the camera angle any more. Now you can setup up to 4 masks to prevent the street from showing up in your surveillance footage.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/6d74e/WebUI-Alarm_Privacy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAAD1UlEQVQ4y6WSW0yTBxSAfxezhz1s0zF9cRlz7PJCTEzUkEwUlGIFyiUUKIXS0haKraXl0nIRuQwQ5T5BBm0pUFpAZjSMbDGwBQLOZCZCBErdNHtYNhiKQmXP30K97GVZluzhy7nknC/n4QixcRKylEpsdgfOvn5sNgd2Ry99/QM4nf10d9twOJy4Bt302Bw4evsYcHnod3kY9IwEcHuGGRz0BBBcLldg4aNPQgnas49jEguSrDYkikYSsjuRqLuJVduJUdsRqx1Eq52IVA6iMjuJTGvipKyZA2FJBAXtITjkIIJr0IXHM8ybb+9FEHYijksnWduKWDuKWDuCSDNElNKJKLOL6IxOTsnbEae3cCr1IqKUBmKyuvkgNAZBEHgrKATB7XYx8tUYJ5KKOX78OKLwj5EqrSiNHeTnGMjN+5zcIjfqQjc5lmG0xUOBXGMZQW29Rrb1GjHptRyO1HDkZB5Cf18vg1fHsRZXUKiMIFHyGZlyPWelIiyKY5TrFZQp46mxlFJuNHDOfpcK9+9Ye5aw2H0Udt0jv3kGXdUNlFYPwszMLN9/N0GNMRadLIyoE2HkyWKwqE5jkEfRUJROseRDXPU55CccpLz+Mi39E9in/sD+g5/OqXUuff0LVe45ztlnEYY8w0zcHKelUolWdpRE8RFSYg9hyI6m1CDFVpHMQO6ntJvEaJIj0EgjyJeF02tvp+fKJZrbmujsvkx19XlKC/IRnj37k9XffqVEGU+1NopKUzLNxSrq9MkYFSLKNdGUZIdToBJRqBaTq5KSl3Eak1KMWRVH03k9zrYyuhosNFaXIaysrPLw4QPMafEYk45SU2ug1WziizNyLlSdoa7ESI1eS11pJY0VBZhLWkjTNaMpuoLK3EFBxZfojFWkpuvIMZxHGB8f5+roKO+/t483Xt+BRhNPtf4MtdmplBapKNWrMcll1FvKuWA1oy+4SJysDJWhiayzreQWdBAemcCO13ayP+QAwuTkJGNjY+wPDubdd3ajUEgxa7IpypCSr8/CpNOQlihHkaZCq8ojJctKhMSAPKeGjNxadKZmDoVFs2vXbkJDDz8Xzs7OMj83z+LCAt6lRe57vTzw+bjv8+H1LvOTz8fCgpeFRR/eZR/Lvp/xen3Mzd/jm29vMjY2zvXrN7g7N/dceOvWLR4/fszW1habm5sBNl7El/j9m/hf1Rv4/X4ePVpjaWmR+fk5FpeWWFtb2/7DGW7fvs3KygpPnjwJiNfX1/8zT58+ZWNjIxC3a+HOj3deCFdfNf8PwtTUFNPT06yu/i3cvvTfeDnzT8K/AFp/cTl7Z5T+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5aa5fe7c3af31dc1ffa6284b44901230/e4706/WebUI-Alarm_Privacy.avif 230w", "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/d1af7/WebUI-Alarm_Privacy.avif 460w", "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/7f308/WebUI-Alarm_Privacy.avif 920w", "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/8a1c1/WebUI-Alarm_Privacy.avif 1085w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5aa5fe7c3af31dc1ffa6284b44901230/a0b58/WebUI-Alarm_Privacy.webp 230w", "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/bc10c/WebUI-Alarm_Privacy.webp 460w", "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/966d8/WebUI-Alarm_Privacy.webp 920w", "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/9c2f0/WebUI-Alarm_Privacy.webp 1085w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5aa5fe7c3af31dc1ffa6284b44901230/81c8e/WebUI-Alarm_Privacy.png 230w", "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/08a84/WebUI-Alarm_Privacy.png 460w", "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/c0255/WebUI-Alarm_Privacy.png 920w", "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/6d74e/WebUI-Alarm_Privacy.png 1085w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5aa5fe7c3af31dc1ffa6284b44901230/c0255/WebUI-Alarm_Privacy.png",
              "alt": "Web User Interface - 720p Series - Multimedia Privacy Mask",
              "title": "Web User Interface - 720p Series - Multimedia Privacy Mask",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrivacyTable mdxType="PrivacyTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "black-out-areas-from-your-surveillance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#black-out-areas-from-your-surveillance",
        "aria-label": "black out areas from your surveillance permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Black out Areas from your Surveillance`}</h3>
    <p>{`The Privacy Mask Menu allows you to black out areas from your surveillance. E.g. you can still see a part of the public street in front of your house on your surveillance videos. But you cannot adjust the camera angle any more. Now you can setup up to 4 masks to prevent the street from showing up in your surveillance footage - just activate an area and draw its shape onto the image.`}</p>
    <h3 {...{
      "id": "flip--mirror",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#flip--mirror",
        "aria-label": "flip  mirror permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Flip & Mirror`}</h3>
    <p>{`Please be aware, that the privacy mask is not affected by the function to Flip & Mirror the camera image. If you installed your camera overhead, first uncheck the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Multimedia/Image/"
      }}>{`Flip & Mirror Boxes`}</a>{`, mask the areas you want to hide and the turn the image upright again.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      